import React, { Component } from 'react';
import { isEqual } from 'lodash';
import ErrorTooltip from '../../../components/formik/ErrorTooltip';
import WarningTooltip from '../../../components/formik/WarningTooltip';
import { TextField, InputAdornment } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  numberFormatRegex,
  removeCommasRegex,
  integerLength16Regex,
  integerLength8Regex
} from '../../../utils/regexes';
import {
  updateIntesForm,
  updateIntesFieldValue,
  updateIntesFormField
} from '../../../store/dataForms/actions';

let prevValue = null;
export class IntesTableInput extends Component {
  componentDidMount() {
    prevValue = null;
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.field, nextProps.field);
  }

  onChange = e => {
    let value = e.target.value;
    const { index, name, updateIntesFieldValue } = this.props;
    if (value) {
      value = value.replace(removeCommasRegex, '');
    }
    if (value) {
      value = String(Number.isNaN(Number(value)) ? value : Number(value));
    }
    if (name === 'currMthlySalesVolAmt') {
      if (integerLength16Regex.test(value)) {
        updateIntesFieldValue(value, name, index);
      }
    } else if (name === 'totMrchCnt') {
      if (integerLength8Regex.test(value)) {
        updateIntesFieldValue(value, name, index);
      }
    }
  };

  onBlur = e => {
    const {
      form,
      field,
      index,
      updateIntesFieldValue,
      updateIntesFormField,
      name,
      total
    } = this.props;
    let error = '';
    let value = e.target.value;
    if (e.target.value === '-') {
      error = 'Invalid Value';
      updateIntesFieldValue(value, name, index, error);
      return;
    }
    if (prevValue === e.target.value) {
      return;
    }
    if (value) {
      value = value.replace(removeCommasRegex, '');
    }
    const body = {
      ...form,
      fieldDetails: [
        {
          ...field,
          [name]: value,
          operationCode: 'U'
        }
      ],
      total: total
    };
    updateIntesFormField(body, index);
  };

  valuesWithComma(value) {
    let updatedValue = '';
    if (value) {
      updatedValue = value.toString().replace(numberFormatRegex, ',');
    }
    return updatedValue;
  }

  saveInitialValue = e => {
    prevValue = e.target.value;
  };

  render() {
    const { field, name, form } = this.props;
    if (!field) {
      return null;
    }
    let endAdornment = '';
    if (name === 'totMrchCnt') {
      if (field.errortotMrchCnt) {
        endAdornment = <ErrorTooltip message={field.errortotMrchCnt} />;
      } else if (field.totMrchCntWarning) {
        endAdornment = <WarningTooltip message={field.totMrchCntWarning} />;
      }
    }
    if (name === 'currMthlySalesVolAmt') {
      if (field.errorcurrMthlySalesVolAmt) {
        endAdornment = (
          <ErrorTooltip message={field.errorcurrMthlySalesVolAmt} />
        );
      } else if (field.currMthlySalesVolAmtWarning) {
        endAdornment = (
          <WarningTooltip message={field.currMthlySalesVolAmtWarning} />
        );
      }
    }

    return form.editable ? (
      <TextField
        fullWidth
        data-test='component-IntesTableInput'
        error={
          (((name === 'totMrchCnt' && field.errortotMrchCnt) ||
            (name === 'currMthlySalesVolAmt' &&
              field.errorcurrMthlySalesVolAmt)) &&
            true) ||
          false
        }
        value={
          (name === 'totMrchCnt' && this.valuesWithComma(field.totMrchCnt)) ||
          (name === 'currMthlySalesVolAmt' &&
            this.valuesWithComma(field.currMthlySalesVolAmt)) ||
          ''
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' style={{ marginRight: 7 }}>
              {endAdornment}
            </InputAdornment>
          )
        }}
        inputProps={{
          style: { textAlign: 'right', color: '#022a48' }
        }}
        onChange={this.onChange}
        onFocus={this.saveInitialValue}
        onBlur={this.onBlur}
      />
    ) : (
      <div className='MuiTypography-root intes-table-form-fields MuiTypography-body1'>
        {(name === 'totMrchCnt' && this.valuesWithComma(field.totMrchCnt)) ||
          (name === 'currMthlySalesVolAmt' &&
            this.valuesWithComma(field.currMthlySalesVolAmt)) ||
          ''}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  form: {
    formId: state.dataForms.form?.formId || '',
    status: state.dataForms.form?.status || '',
    orgProfileId: state.dataForms.form?.orgProfileId || '',
    reportingMonth: state.dataForms.form?.reportingMonth || '',
    reportingYear: state.dataForms.form?.reportingYear || '',
    editable: state.dataForms.form?.formEditable || false
  }
});

const mapDispatchToProps = {
  updateIntesForm,
  updateIntesFieldValue,
  updateIntesFormField
};

export default connect(mapStateToProps, mapDispatchToProps)(IntesTableInput);
