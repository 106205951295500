import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';
import { Loader } from '../../../components';
import { TableInput } from '../../../components/generictable';
import { range } from '../../../utils/commonFunctions';
import {
  setDataForms,
  changeTab,
  resetDataForms
} from './../../../store/dataForms/actions';
export class QRCode extends Component {
  componentDidMount() {
    this.props.setDataForms(this.props.location.pathname);
  }

  componentWillUnmount() {
    this.props.resetDataForms();
  }

  renderSection1 = section => {
    const cols = Object.entries(section.columnHeaders);
    const nCols = cols.length;
    return (
      <Table
        data-test='component-table'
        className='data-form-table qr-code section-0'>
        <TableHead data-test='component-table-head' className='header-1'>
          <TableRow data-test='component-table-head-row'>
            <TableCell className='qr-code-upload-right'>
              {section.sectionName}
            </TableCell>
            {cols.map(([colId, colName]) => {
              return (
                <TableCell key={colId} data-test='component-table-head-cell'>
                  {colName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody data-test='component-table-body'>
          {Object.entries(section.rowHeaders).map(
            ([rowKey, rowVal], rindex) => {
              return (
                <TableRow
                  key={rowKey}
                  data-test='component-table-body-row'
                  className={`dataform-row row-${rindex}`}>
                  <TableCell
                    className={`row-label row-${rindex}`}
                    aria-label={`row ${rowVal}`}>
                    {rowVal}
                  </TableCell>
                  {range(nCols).map(col => (
                    <TableCell
                      key={col}
                      className={`row-${rindex} col-${col + 1}`}>
                      <TableInput
                        index={col + rindex * nCols}
                        sectionIndex={0}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    );
  };

  renderSection2 = section => {
    const cols = Object.entries(section.columnHeaders);
    const nCols = cols.length;
    return (
      <Table
        data-test='component-table'
        className='data-form-table qr-code section-1'>
        <TableHead data-test='component-table-head'>
          <TableRow
            className='header-1'
            style={{ borderBottom: '1px solid #d0c9c9' }}>
            <TableCell />
            <TableCell colSpan={3} className='col-1 qr-code-upload-center'>
              Service Establishment Information
            </TableCell>
            <TableCell colSpan={3} className='qr-code-upload-center'>
              Terminal Information
            </TableCell>
          </TableRow>
          <TableRow data-test='component-table-head-row' className='header-2'>
            <TableCell />
            {cols.map(([colId, colName], cIndex) => {
              return (
                <TableCell
                  key={colId}
                  className={`col-${cIndex + 1}`}
                  data-test='component-table-head-cell'>
                  {colName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody data-test='component-table-body'>
          {Object.entries(section.rowHeaders).map(
            ([rowKey, rowVal], rindex) => {
              return (
                <TableRow
                  key={rowKey}
                  data-test='component-table-body-row'
                  className={`dataform-row row-${rindex}`}>
                  <td
                    className={`row-label row-${rindex}`}
                    aria-label={`row ${rowVal}`}>
                    {rowVal}
                  </td>
                  {range(nCols).map(col => (
                    <td key={col} className={`row-${rindex} col-${col + 1}`}>
                      <TableInput
                        index={col + rindex * nCols}
                        sectionIndex={1}
                      />
                    </td>
                  ))}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { forms } = this.props;
    const form = forms && forms.tabs ? forms.tabs[0] : undefined;

    if (!form || !form.sections || form.sections.length < 2) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='component-franchise'
          data-testid='component-qr-code'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }

    return (
      <div
        className={`table-wrapper ${
          this.props.location.pathname.split('/')[3]
        }`}
        data-testid='component-se-sales-volume'>
        {this.renderSection1(form.sections[0])}
        {this.renderSection2(form.sections[1])}
      </div>
    );
  }
}

QRCode.propTypes = {
  tableName: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    forms: state.dataForms.form,
    tableName: 'QRCode'
  };
};

export const mapDispatchToProps = {
  setDataForms,
  changeTab,
  resetDataForms
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QRCode));
