/* istanbul ignore file */

const TEXT = {
  brandName: 'GlobalNet',
  rebrandedName: 'DFS Services LLC',
  brandCaption: 'The Franchise Reporting System',
  copyrightText: 'Copyright 2020. DCI GlobalNet, All Rights Reserved',
  lastLoggedIn: 'Last Logged in: ',
  hello: 'Hello',
  logout: 'Logout',
  manageData: 'manage-data',
  admin: 'admin',
  settings: 'settings',
  profile: 'profile',
  networkErrorText: 'The network connection is lost',
  issuerFraudConfirmationMsg:
    'By making this selection the blank fields will be removed from this page, do you wish to proceed?',
  royaltyOthDescRequiredErrorMsg:
    'Description is required for Other type royalty',
  royaltyTypeDuplicateErrorMsg:
    'Description is already present for reporting period'
};

export default TEXT;
