import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MegaMenuContainer from './MegaMenuContainer';
import {
  openMegaMenu,
  setAdminMenuActive,
  setDataMenuActive,
  setSettingsMenuActive,
  setMenuInactive,
  setManageDataActive
} from '../../store/ui';

export const mapStateToProps = state => ({
  selectedMegaMenu: state.ui.selectedMegaMenu,
  orgReportingPeriod: state.formsListNav.orgReportingPeriod,
  tempOrgReportingPeriod: state.dataForms.tempFormsListNav
    ? state.dataForms.tempFormsListNav.orgReportingPeriod
    : {},
  selectedOrgReportedPeriod: state.formsListNav.selectedOrgReportedPeriod,
  form: state.dataForms.form
});

const mapDispatchToProps = {
  openMegaMenu,
  setAdminMenuActive,
  setDataMenuActive,
  setSettingsMenuActive,
  setMenuInactive,
  setManageDataActive
};
export { MegaMenuContainer };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MegaMenuContainer)
);
