import React from 'react';
import { Table, TableContainer, TableBody } from '@material-ui/core';
import FormContainer from './FormContainer';
import TableHeaders from './TableHeaders';
import ExtensionRequestTableList from './ExtensionRequestTableList';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import { format } from 'date-fns';
import * as Yup from 'yup';
import { withFormik } from 'formik';

export const ExtensionRequestTable = props => {
  const {
    isValid,
    dirty,
    tableHeaders,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    errors,
    touched,
    usersOrganizations,
    brandingType,
    extensionRequestUpdate,
    exchangeRequestData,
    getAllExchangeRequest
  } = props;
  const handleCurrentChange = () => {
    handleSubmit();
  };
  const handleClearChange = () => {
    resetForm();
  };
  return (
    <>
      <TableContainer
        className='product-line-table'
        data-test='product-line-table'>
        <Table data-test='product-line-main-table'>
          <TableHeaders
            data-test='product-line-table-header'
            headers={tableHeaders}
          />
          <TableBody data-test='product-line-table-body'>
            <FormContainer
              values={values}
              data-test='product-line-form-container'
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              resetForm={resetForm}
              branding={brandingType}
              errors={errors}
              touched={touched}
              isValid={isValid}
              dirty={dirty}
              adminsOrganizations={props.usersOrganizations}
              handleCurrentChange={handleCurrentChange}
              handleClearChange={handleClearChange}
            />

            <ExtensionRequestTableList
              data-test='product-line-form-list-container'
              exchangeRequestData={exchangeRequestData}
              usersOrganizations={usersOrganizations}
              extensionRequestUpdate={extensionRequestUpdate}
              getAllExchangeRequest={getAllExchangeRequest}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    orgProfId: '',
    formTypeCategory: [],
    extensionExpiryDate: null,
    extensionRequestNote: '',
    extensionRequestIndicator: false
  }),
  mapPropsToStatus: () => ({}),

  validationSchema: Yup.object().shape({
    orgProfId: Yup.object().required('Participant Name is required'),
    formTypeCategory: Yup.array()
      .min(1, 'Select at least one category')
      .required('Select Category is required'),
    extensionExpiryDate: Yup.date().required('Select date is required'),
    extensionRequestNote: Yup.string().required('Comment is required'),
    extensionRequestIndicator: Yup.boolean().required(
      'Waiver Toggle is required'
    )
  }),
  handleSubmit: (values, formikbag) => {
    const selectedOrgCategory = values.formTypeCategory.map(
      category => category.value
    );
    const formattedDate = format(values.extensionExpiryDate, 'dd-MM-yyyy');
    const data = {
      extensionExpiryDate: formattedDate,
      extensionRequestIndicator:
        values.extensionRequestIndicator === true ? 'Y' : 'N',
      extensionRequestNote: values.extensionRequestNote.trimStart().trimEnd(),
      formTypeCategory: selectedOrgCategory,
      orgProfId: values.orgProfId.id
    };
    formikbag.props
      .createExtensionRequest(data)
      .then(response => {
        if (response.status === 200) {
          toastFunc({
            content: `Extention for ${values.orgProfId.label} Participant added successfully`,
            config: { className: 'toast-container accept' },
            toastType: 'success'
          });
          formikbag.resetForm();
          formikbag.props.getAllExchangeRequest();
        } else {
          toastFunc({
            content: `${response.data.errorMessage}`,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
          formikbag.resetForm();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
});

export default formConfig(ExtensionRequestTable);
