import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IntesTable from './IntesTable';
import {
  setDataForms,
  resetDataForms,
  updateIntesForm,
  deleteIntesFormRow
} from './../../../store/dataForms/actions';
import { getIntesData } from '../../../store/formsListNav/actions';
import { Loader } from '../../../components';
import { Grid } from '@material-ui/core';
export class Intes extends React.Component {
  state = {
    columnHeaders: {
      1200: 'INTES Code & Name',
      1201: 'Number of Merchant locations at end of Period',
      1202: 'Year-to-Date Sales Volume through Prior Month End',
      1203: 'Current Month Sales Volume',
      1204: 'New Year to Date Total',
      1205: 'Actions'
    }
  };

  componentDidMount() {
    this.props.setDataForms(this.props.location.pathname);
  }

  componentWillUnmount() {
    this.props.resetDataForms();
  }

  render() {
    const {
      fieldDetails,
      formObject,
      updateIntesForm,
      getIntesData,
      intesDropdownData,
      deleteIntesFormRow,
      total
    } = this.props;
    const { columnHeaders } = this.state;

    if (!formObject || !formObject.status) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='component-franchise'
          data-testid='component-qr-code'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }

    return (
      <IntesTable
        form={formObject}
        fieldDetails={fieldDetails}
        intesDropdownData={intesDropdownData}
        updateIntesForm={updateIntesForm}
        getIntesData={getIntesData}
        columnHeaders={columnHeaders}
        deleteIntesFormRow={deleteIntesFormRow}
        total={total}
      />
    );
  }
}

export const mapStateToProps = state => {
  return {
    fieldDetails: state.dataForms.form?.fieldDetails || [],
    total: state.dataForms.form?.total || {},
    formObject: state.dataForms?.form || {},
    tableName: 'intes',
    intesDropdownData: state.formsListNav?.intesDropdownData || []
  };
};

export default connect(mapStateToProps, {
  setDataForms,
  updateIntesForm,
  resetDataForms,
  getIntesData,
  deleteIntesFormRow
})(withRouter(Intes));
