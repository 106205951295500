import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { landingPageText } from '../../constants/text';
import { copyrightYear } from '../../utils/commonFunctions';
import TEXT from '../../constants/text/common';

const WelcomeBanner = () => {
  return (
    <Grid
      container
      item
      data-test='component-welcome-banner'
      role='banner'
      aria-label='Welcome Banner'>
      <div className='banner' data-test='banner'>
        <Typography
          variant='h5'
          className='top-line'
          color='secondary'
          data-test='welcome'
          aria-label={landingPageText.welcome}>
          {landingPageText.welcome}
        </Typography>
        <Typography
          className='bottom-line-welcomeHeading'
          color='primary'
          data-test='welcome-heading'
          aria-label={landingPageText.welcomeHeading}>
          {landingPageText.welcomeHeading}
        </Typography>
        <Typography
          variant='h1'
          className='bottom-line'
          color='primary'
          data-test='welcome-subheading'
          aria-label={landingPageText.welcomeSubHeading}>
          {landingPageText.welcomeSubHeading}
        </Typography>
      </div>
      <div className='foot-note' data-test='footer-note'>
        <Typography
          variant='caption'
          data-test='footer-note-text'
          className='foot-note-text'
          aria-label='Footer Text'>
          {`Copyright ©${copyrightYear()}, ${TEXT.rebrandedName}`}
        </Typography>
      </div>
    </Grid>
  );
};

export default WelcomeBanner;
