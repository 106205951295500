import moment from 'moment';
import Holidays from 'date-holidays';
import { numberFormatRegex } from './regexes';

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

/**
 * @function convertCountToDegreeValue A utility function to convert timestamp into date time string
 * @param {Number} total total no of object.
 * @param {Number} value value out of total.
 * @returns {Number} percentage of value with respect to total
 */
export const countToPercentage = (total, value) => {
  if (!value || !total) {
    return 0;
  }
  return (value / total) * 180;
};

export const reduceTable = (state, action) => {
  return {
    ...state,
    tablePages: state.tablePages.map((table, index) => {
      if (index !== action.payload.table) {
        return table;
      }
      return {
        ...table,
        tableObject: {
          ...table.tableObject,
          tableBody: table.tableObject.tableBody.map((row, index) => {
            if (index !== action.payload.row) {
              return row;
            }
            return {
              ...row,
              rowData: row.rowData.map((column, index) => {
                if (index !== action.payload.column) {
                  return column;
                }
                return {
                  ...column,
                  value: action.payload.value
                };
              })
            };
          })
        }
      };
    })
  };
};

export const colorClassSwitch = (status, dueDate) => {
  if (status === 'Submitted') {
    return 'submitted-color';
  }
  if (
    status === 'Not Submitted' &&
    moment(dueDate, 'DD/MM/YYYY')
      .fromNow()
      .includes('ago')
  ) {
    return 'not-submitted-color';
  }
};

export const rowHasError = data => {
  return data.some(item => item.hasError);
};

export const tableHasError = tableBody => {
  return tableBody.some(row => rowHasError(row.rowData));
};
export const ucFirstString = string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const sortObject = (data, sortBy) => {
  return data.sort((a, b) => {
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    return 0;
  });
};

/* Two helper methods used in ImportantDates.jsx for generating the important dates
also checks to see if the Important Date falls on a Holiday or Weekend and adjusts the date accordingly */

/* check if day is on a holiday or weekend */
export const checkIfDayOnWeekendOrHoliday = date => {
  const todaysDate = new Date();
  const hd = new Holidays('US');
  const holidays = hd.getHolidays(todaysDate.getFullYear());
  let validDay = false;
  let day = 10;
  while (!validDay) {
    if (date.getDay() === 0) {
      // If day is sunday, move to the 11th
      date.setDate(date.getDate() + 1);
      day = 11;
    } else if (date.getDay() === 6) {
      // If day is saturday, move to the 12th
      date.setDate(date.getDate() + 2);
      day = 12;
    } else if (
      holidays.find(d => d.start <= date && date < d.end && d.type === 'public')
    ) {
      date.setDate(date.getDate() + 1); // Add one to the date
      day = date.getDate();
    } else {
      validDay = true;
    }
  }
  return day;
};
/* Creates object for converting UTC to date object for given UTC */
export const createUTCtoDate = date => {
  const initialDate = new Date(date);
  return {
    date: initialDate.getDate(),
    monthNum: initialDate.getMonth(),
    monthName: months[initialDate.getMonth()],
    year: initialDate.getFullYear()
  };
};

/* Used in the instance of when we want to add an internal to a month. Ex: if we want Oct. + 6 that would be 16. So we need to loop back around */
export const getMonthNumAfterInterval = interval => {
  const todaysDate = new Date();
  const todaysMonth = todaysDate.getMonth();

  if (todaysMonth >= 7) {
    const numToSubtract = 12 - interval;
    return todaysMonth - numToSubtract;
  }
  const month = todaysMonth + interval;
  return month > 11 ? month - 12 : month;
};

/* Create open date for each month */
export const createOpenDate = () => {
  const todaysDate = new Date();
  return {
    name: 'Reporting Cycle Open Date',
    day: 1, // Needs to be changed for bank holiday
    monthNum: todaysDate.getMonth() + 1,
    monthName: months[todaysDate.getMonth()],
    year: todaysDate.getFullYear()
  };
};
/* Creates object for due date for given interval */
export const createDueDate = interval => {
  const todaysDate = new Date();
  todaysDate.setDate(10);
  switch (interval) {
    case 'Monthly':
      todaysDate.setMonth(todaysDate.getMonth() + 1);
      break;
    case 'Semi Annual':
      todaysDate.setMonth(todaysDate.getMonth() + 6);
      break;
    case 'Annual':
      todaysDate.setMonth(todaysDate.getMonth() + 12);
      break;
    default:
      return {
        error: true
      };
  }

  return {
    name: interval,
    day: checkIfDayOnWeekendOrHoliday(todaysDate),
    monthNum: todaysDate.getMonth(),
    monthName: months[todaysDate.getMonth()],
    year: todaysDate.getFullYear()
  };
};
export const range = (start, stop, step = 1) => {
  if (typeof stop === 'undefined') {
    // One param defined
    stop = start;
    start = 0;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  const result = [];
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
};

export const rowTotal = rowData => {
  return rowData.reduce((acc, currVal) => {
    return currVal.value !== '-'
      ? Number(acc) + Number(currVal.value)
      : Number(acc);
  }, 0);
};

export const tableCellAriaLabel = (tableObject, row, col) => {
  const rowLabel =
    (tableObject.tableBody && tableObject.tableBody[row].rowLabel) || row;
  const columnLabel =
    (tableObject.tableHeader && tableObject.tableHeader[col + 1]) || col;
  return `row ${rowLabel} column ${columnLabel}`;
};

/* Returns Formated Date and time string */
export const formatTimeInfo = (timeStamp, showTime = false) => {
  /* For Internet Explorer Start */
  let ieCompatibleStr = timeStamp;
  if (ieCompatibleStr !== null) {
    ieCompatibleStr = ieCompatibleStr
      .replace(/-/g, '/')
      .replace('T', ' ')
      .replace(/(?:\.\w+)?\+/, ' GMT+')
      .toString();
  }
  /* For Internet Explorer End */
  const currentDate = new Date(ieCompatibleStr);
  const date = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  month = month < 10 ? `0${month}` : month;

  const dateString = date + '/' + month + '/' + year;

  const timeString = currentDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return showTime ? `${dateString} ${timeString} ` : dateString;
};

export const createStatusFromErrors = err => {
  const error = err.response ? err.response.data : err.data;
  const errorMaps = error ? (error.errorMap ? error.errorMap : {}) : {};
  const root = error ? (error.errorMessage ? error.errorMessage : '') : '';
  return { ...errorMaps, root };
};

export const readFileDataAsBase64 = e => {
  const file = e.target.files[0];
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      resolve(event.target.result);
    };

    reader.onerror = err => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
};

export const validateFileSizeAndFormat = (size, format, fieldErrors) => {
  const allowedFormats = ['jpg', 'jpeg', 'png'];
  if (size > 5 * 1024 * 1024) {
    return fieldErrors.announcementImage.fileSize;
  }
  if (!allowedFormats.includes(format)) {
    return fieldErrors.announcementImage.invalidFormat;
  }
  return 'valid';
};

export const copyrightYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

export const OR = (a, b) => a || b;

export const AND = (a, b) => a && b;

export const numberCommaFormat = a =>
  a.toString().replace(numberFormatRegex, ',');
