import { datadogRum } from '@datadog/browser-rum';

function isProd() {
  if (window.location.hostname === 'localhost') {
    return false;
  }
  const cookies = document.cookie.split(';');
  let isProdEnv = true;

  for (let i = 0; i < cookies.length; i++) {
    let name = cookies[i].substring(0, cookies[i].indexOf('='));
    name = name.trim();
    if (name === 'env') {
      isProdEnv = false;
      break;
    }
  }
  return isProdEnv;
}

function getDDEnv() {
  return isProd() ? 'prod' : 'non-prod';
}

datadogRum.init({
  applicationId: '24b13aa9-7dc5-437a-916b-9af5f0b8c2db',
  clientToken: 'pubeaea903f997fdc89d2ccf176ba57b4a4',
  site: 'datadoghq.com',
  service: 'dci-ap-globalnet-react',
  env: getDDEnv(),
  trackUserInteractions: true,
  actionNameAttribute: 'data-test',
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask',
  sessionSampleRate: 20,
  sessionReplaySampleRate: 0,
  silentMultipleInit: false,
  allowedTracingUrls: [
    'https://www.dinersclubglobalnet.com/',
    /https:\/\/.*\.dinersclubglobalnet\.com/,
    url => url.startsWith('https://www.dinersclubglobalnet.com/')
  ],
  traceSampleRate: 100,
  telemetrySampleRate: 20,
  allowUntrustedEvents: false,
  trackSessionsAcrossSubdomains: true,
  useSecureSessionCookie: true
});

export default datadogRum;
