import React, { Component } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ComboboxGrid, TextFieldGrid } from '../../../components/formik';
import { TableCell, TableRow } from '@material-ui/core';
import {
  integerLength16Regex,
  integerLength8Regex
} from '../../../utils/regexes';

class FormRow extends Component {
  componentDidMount() {
    this.props.getIntesData();
  }

  onChange = e => {
    let value = e.target.value;
    if (value) {
      value = String(Number.isNaN(Number(value)) ? value : Number(value));
    }
    if (e.target.name === 'merchantLocations') {
      if (integerLength8Regex.test(value)) {
        this.props.handleChange(e);
      }
    } else if (e.target.name === 'currentSalesVolume') {
      if (integerLength16Regex.test(value)) {
        this.props.handleChange(e);
      }
    }
  };

  render() {
    const {
      handleChange,
      handleSubmit,
      handleClear,
      intesDropdownData
    } = this.props;

    return (
      <TableRow
        className='form-row'
        data-test='component-dynamic-row-container'>
        <TableCell>
          <ComboboxGrid
            highlight
            data-test='intes-dropdown-field'
            name='intesCode'
            label='Select/Type'
            placeholder=''
            options={intesDropdownData.map(data => ({
              value: data.intesCode,
              label: data.intesNames
            }))}
            className='intes-table-form-fields'
            onChange={handleChange}
          />
        </TableCell>
        <TableCell>
          <TextFieldGrid
            fullWidth
            name='merchantLocations'
            data-test='merchantLocations-table-input'
            aria-label='Number of Merchant Locations at End of Period'
            className='intes-table-form-fields'
            inputProps={{
              style: { textAlign: 'right', color: '#022A48' }
            }}
            onChange={event => {
              const regex = /^[0-9]*$/;
              if (event.target.value === '' || regex.test(event.target.value)) {
                this.onChange(event);
              }
            }}
          />
        </TableCell>
        <TableCell />
        <TableCell>
          <TextFieldGrid
            fullWidth
            name='currentSalesVolume'
            data-test='currentSalesVolume-table-input'
            aria-label='Current Month Sales Volume'
            className='intes-table-form-fields'
            inputProps={{
              style: { textAlign: 'right', color: '#022a48' }
            }}
            onChange={event => {
              const regex = /^[0-9]*$/;
              if (event.target.value === '' || regex.test(event.target.value)) {
                this.onChange(event);
              }
            }}
          />
        </TableCell>
        <TableCell />
        <TableCell className='intes-form-actions-fields'>
          <span className='add' onClick={handleSubmit}>
            <PlusIcon />
          </span>
          <span className='cross' onClick={handleClear}>
            <PlusIcon />
          </span>
        </TableCell>
      </TableRow>
    );
  }
}

export default FormRow;
